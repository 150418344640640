nav {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(45deg, rgb(156, 14, 156), midnightblue);
}

.liste {
  list-style-type: none;
  background: linear-gradient(45deg, rgb(156, 14, 156), midnightblue);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 22px;
}

.items {
  margin: 0 15px;
  text-transform: uppercase;
  color: #f1f1f1;
  cursor: pointer;
}

.btn {
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;
  color: #000;
  font-size: 18px;
}

.liste .items a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 500px) {

  .liste {
    flex-direction: column;
    height: auto;
  }

  .items:nth-child(1) {
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: 50px;
  }
  
  .items {
    height: 70px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    line-height: 70px;
    text-align: center;
  }

  .btn {
    display: block;
  }

}
  
