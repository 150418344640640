.container-contact {
  max-width: 700px;
  margin: 100px auto 0;
  font-size: 25px;
}

.container-contact h1 {
  font-size: 50px;
  margin-bottom: 10px;
} 

.container-contact p:nth-child(4) {
  margin-top: 60px;
}
