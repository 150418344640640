.title-form {
  text-align: center;
  font-size: 55px;
  margin: 70px 0 0;
}

.container-form {
  padding: 10px;
  max-width: 900px;
  margin: 30px auto 0;
  display: flex;
  flex-direction: column;
}

.container-form label {
  font-size: 20px;
}

.container-form input, .container-form textarea {
  padding: 10px;
  margin-bottom: 25px;
  font-size: 18px;
}

.container-form textarea {
  min-height: 200px;
  font-family: Arial, Helvetica, sans-serif;
}

.container-form button {
  padding: 10px 15px;
  width: 200px;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.8);
  border-radius: 3px;
  cursor: pointer;
}